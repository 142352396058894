
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutOS7HpsGFDFSTbbd4iXDP92Iuvckam0GQw5dgZEXCgBIMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as indexG_WfXOzFIdmY7nC2wskK9_5LGE8hGwIJyZEyCaXLOuMMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as loginyiubrZraL9EDD3J38Z_45ftLpCUym9OoYm1Hb9453rWnMMeta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as contact6cZoOGxJBTY9l_45DSf37OWQKprIBU1PffJx7PVn14dCsMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as supportnA_45IDlPaAjaQsA8ejz2uVrUOW723JN_MsEEEqK7KeZEMeta } from "/vercel/path0/pages/support.vue?macro=true";
import { default as indexDIlA_VwVYEsF82JYYTptIRBexsOVSbsbgl1xBQ_lIIoMeta } from "/vercel/path0/pages/blog/index.vue?macro=true";
import { default as index_456zRNQiVe_45IfAhPosx5rkA9KFnZs90aMKtTi3ZwMa18Meta } from "/vercel/path0/pages/join/index.vue?macro=true";
import { default as index7GapGVumHfWSdXaIMC1BxBSpePyPOLJ7NYt7i3HIPo8Meta } from "/vercel/path0/pages/play/index.vue?macro=true";
import { default as _91slug_93v3ez11IMRk2feKAiBpS9mVspk4W7_E8tt32O22_45Wq2cMeta } from "/vercel/path0/pages/blog/[slug].vue?macro=true";
import { default as successNTWFCXaaWVUMqWz6WsXXDJqacqTWXgoNSqCmxaNpk6YMeta } from "/vercel/path0/pages/join/success.vue?macro=true";
import { default as indexeHpHCnyT0Eqq25HHbMqohPuuqoEbE1Pn3qBr8Tsl45AMeta } from "/vercel/path0/pages/explore/index.vue?macro=true";
import { default as _91slug_93DEt958uJiHcVtbA70DRRKXkMLNVeGpjCRhya0EBP9W8Meta } from "/vercel/path0/pages/policy/[slug].vue?macro=true";
import { default as _91slug_93x83haUPAwqnnXMtsCqs6nHVRv5dGo_45lpZ0u4y2PDL1cMeta } from "/vercel/path0/pages/article/[slug].vue?macro=true";
import { default as favoritesuguIER1Y3gCT_45Zbt1rf7L78_kB8fgK5Qwl44ZmdLY1EMeta } from "/vercel/path0/pages/play/favorites.vue?macro=true";
import { default as _91slug_93AFaglAn1f6U_45I1cpZF4WmNrLz6UTvpuf8lFd_45k5Rn7QMeta } from "/vercel/path0/pages/rudiment/[slug].vue?macro=true";
import { default as _91slug_93_45rkrcO_nRiItKIQhE9g1an2jHWgWYOU_Ffisqsap99gMeta } from "/vercel/path0/pages/collection/[slug].vue?macro=true";
import { default as indexc3UQwleLqM8wo4GjHa_SwO9efADMsHQALn_45nfFUUfJUMeta } from "/vercel/path0/pages/collections/index.vue?macro=true";
import { default as index4dwqhxVj969PveQzDIphW7UID1Cz6CvMwcVvccMHo3YMeta } from "/vercel/path0/pages/play/account/index.vue?macro=true";
import { default as index1L82zWW6X0Vo7oum_45kr6gGD_IpcW7TWOCu6tBk88mv0Meta } from "/vercel/path0/pages/play/explore/index.vue?macro=true";
import { default as notificationsVsUKTSQf7B6makkhDy6yiGJp3avKVQT_o0gom1WJSzwMeta } from "/vercel/path0/pages/play/notifications.vue?macro=true";
import { default as _91slug_93b4vpw4ZtHUO3Qnz3kYfXxB7say0ea5Ib_lGs65ruO6AMeta } from "/vercel/path0/pages/play/rudiment/[slug].vue?macro=true";
import { default as _91slug_93jIMhZRu3J5Kvh2_TCOeaPBte591jBCx1udesA4HZZ_sMeta } from "/vercel/path0/pages/play/collection/[slug].vue?macro=true";
import { default as indexjKZ5WnyNH103Ia3EYtqPO_45t1TvMn_Q257_zAhZn_j2EMeta } from "/vercel/path0/pages/play/collections/index.vue?macro=true";
import { default as _91slug_934MiJNkgAZl9r_LrTj0oRYVbIQUxSGDmxnibaIyPEpzUMeta } from "/vercel/path0/pages/perform/rudiment/[slug].vue?macro=true";
import { default as subscriptionH7NPDXfg7HhGcAW94X3X6vK2o1f_45R01HL5VRFRRxfLsMeta } from "/vercel/path0/pages/play/account/subscription.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/vercel/path0/pages/about.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/vercel/path0/pages/support.vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/vercel/path0/pages/blog/index.vue")
  },
  {
    name: "join",
    path: "/join",
    component: () => import("/vercel/path0/pages/join/index.vue")
  },
  {
    name: "play",
    path: "/play",
    meta: index7GapGVumHfWSdXaIMC1BxBSpePyPOLJ7NYt7i3HIPo8Meta || {},
    component: () => import("/vercel/path0/pages/play/index.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/vercel/path0/pages/blog/[slug].vue")
  },
  {
    name: "join-success",
    path: "/join/success",
    component: () => import("/vercel/path0/pages/join/success.vue")
  },
  {
    name: "explore",
    path: "/explore",
    component: () => import("/vercel/path0/pages/explore/index.vue")
  },
  {
    name: "policy-slug",
    path: "/policy/:slug()",
    component: () => import("/vercel/path0/pages/policy/[slug].vue")
  },
  {
    name: "article-slug",
    path: "/article/:slug()",
    component: () => import("/vercel/path0/pages/article/[slug].vue")
  },
  {
    name: "play-favorites",
    path: "/play/favorites",
    meta: favoritesuguIER1Y3gCT_45Zbt1rf7L78_kB8fgK5Qwl44ZmdLY1EMeta || {},
    component: () => import("/vercel/path0/pages/play/favorites.vue")
  },
  {
    name: "rudiment-slug",
    path: "/rudiment/:slug()",
    component: () => import("/vercel/path0/pages/rudiment/[slug].vue")
  },
  {
    name: "collection-slug",
    path: "/collection/:slug()",
    component: () => import("/vercel/path0/pages/collection/[slug].vue")
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/vercel/path0/pages/collections/index.vue")
  },
  {
    name: "play-account",
    path: "/play/account",
    meta: index4dwqhxVj969PveQzDIphW7UID1Cz6CvMwcVvccMHo3YMeta || {},
    component: () => import("/vercel/path0/pages/play/account/index.vue")
  },
  {
    name: "play-explore",
    path: "/play/explore",
    meta: index1L82zWW6X0Vo7oum_45kr6gGD_IpcW7TWOCu6tBk88mv0Meta || {},
    component: () => import("/vercel/path0/pages/play/explore/index.vue")
  },
  {
    name: "play-notifications",
    path: "/play/notifications",
    meta: notificationsVsUKTSQf7B6makkhDy6yiGJp3avKVQT_o0gom1WJSzwMeta || {},
    component: () => import("/vercel/path0/pages/play/notifications.vue")
  },
  {
    name: "play-rudiment-slug",
    path: "/play/rudiment/:slug()",
    meta: _91slug_93b4vpw4ZtHUO3Qnz3kYfXxB7say0ea5Ib_lGs65ruO6AMeta || {},
    component: () => import("/vercel/path0/pages/play/rudiment/[slug].vue")
  },
  {
    name: "play-collection-slug",
    path: "/play/collection/:slug()",
    meta: _91slug_93jIMhZRu3J5Kvh2_TCOeaPBte591jBCx1udesA4HZZ_sMeta || {},
    component: () => import("/vercel/path0/pages/play/collection/[slug].vue")
  },
  {
    name: "play-collections",
    path: "/play/collections",
    meta: indexjKZ5WnyNH103Ia3EYtqPO_45t1TvMn_Q257_zAhZn_j2EMeta || {},
    component: () => import("/vercel/path0/pages/play/collections/index.vue")
  },
  {
    name: "perform-rudiment-slug",
    path: "/perform/rudiment/:slug()",
    meta: _91slug_934MiJNkgAZl9r_LrTj0oRYVbIQUxSGDmxnibaIyPEpzUMeta || {},
    component: () => import("/vercel/path0/pages/perform/rudiment/[slug].vue")
  },
  {
    name: "play-account-subscription",
    path: "/play/account/subscription",
    meta: subscriptionH7NPDXfg7HhGcAW94X3X6vK2o1f_45R01HL5VRFRRxfLsMeta || {},
    component: () => import("/vercel/path0/pages/play/account/subscription.vue")
  }
]